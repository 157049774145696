<template>
  <ux-atoms-link :to="url" class="Logo">
    <span class="accessibility-sr-only">{{ $t('ux.atoms.logo.allAccorLiveLimitlessRNB') }}</span>
    <div class="Logo-all">
      <ux-atoms-icon class="Logo-all--small" name="logo-small" size="m" />
      <ux-atoms-icon class="Logo-all--large" name="logo" size="m" />
    </div>
    <div class="Logo-baseline">
      <ux-atoms-icon name="logo-baseline" size="m" />
    </div>
  </ux-atoms-link>
</template>
<script lang="ts" setup>
const { baseCountry, redirectFromLogo } = useCountry();
const localePath = useLocalePath();
const url = ref('');

watch(
  baseCountry,
  async (newBaseCountry) => {
    try {
      if (newBaseCountry) {
        const newUrl = await redirectFromLogo();
        url.value = newUrl;
      } else {
        url.value = localePath('/');
      }
    } catch (error) {
      url.value = localePath('/');
    }
  },
  { immediate: true }
);
</script>
<style lang="scss" scoped>
@use 'Logo.scss';
</style>
