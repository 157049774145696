<template>
  <transition name="fade">
    <div class="Main-nav-menu Grid-layout" v-show="dropdownIsOpen">
      <ul class="Main-nav-menu__list">
        <li :key="headerLink.href" class="Main-nav-menu__element" v-for="headerLink in headerLinks">
          <ux-atoms-link
            :class="['Main-nav-menu__element-link', { 'Main-nav-menu__element-link--selected': headerLink.isSelected?.value }]"
            :classic="false"
            :href="headerLink.href"
            :target="headerLink.target as LinkProps['target']"
            :text="headerLink.label"
            @click="navigate(headerLink.label)"
          />
        </li>
        <li class="Main-nav-menu__element">
          <ux-molecules-lang-switch />
        </li>
      </ul>
    </div>
  </transition>
</template>
<script setup lang="ts">
import { getPageName } from '~/helpers/getPageName';
import { gtmEvent, gtmPageName } from '~/helpers/gtm';

import { LinkProps } from '../../atoms/Link/Link.vue';

const localePath = useLocalePath();
const route = useRoute();

export interface MainNavMenu {
  dropdownIsOpen?: boolean;
}

withDefaults(defineProps<MainNavMenu>(), {
  dropdownIsOpen: false
});
const emit = defineEmits(['dropdown::close']);
const inspirationLink = computed(() => {
  return localePath({
    name: 'inspiration'
  });
});

const { locale, t } = useI18n();
const isInspiration = computed(() => getPageName(route.name)?.startsWith('inspiration___'));
const headerLinks = [
  {
    href: inspirationLink.value,
    isSelected: isInspiration,
    label: t('ux.molecules.headerNav.inspirations'),
    target: '_self'
  },
  {
    href: `https://all.accor.com/loyalty-program/reasonstojoin/index.${locale.value}.shtml`,
    label: t('ux.molecules.headerNav.loyaltyProgram'),
    target: '_blank'
  }
];

const navGtmEvent = (label: string) => {
  if (label === t('ux.molecules.headerNav.loyaltyProgram')) {
    gtmEvent('menu_interact', {
      cta_name: 'filters',
      menu_label: 'loyalty program',
      pagename: gtmPageName(route.name as string)
    });
  } else {
    gtmEvent('menu_interact', {
      cta_name: 'filters',
      menu_label: 'inspiration',
      pagename: gtmPageName(route.name as string)
    });
  }
};

const navigate = (label: string) => {
  navGtmEvent(label);
  emit('dropdown::close');
};
</script>
<style lang="scss" scoped>
@use 'MainNavMenu.scss';
</style>
