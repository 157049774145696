<template>
  <div @keydown.down="focusNextListItem($event)" @keydown.up="focusPreviousListItem($event)" class="Nav-account-dropdown" ref="navAccountDropdown">
    <div class="Nav-account-dropdown__logged" v-if="userStore.hasLoadedUser">
      <div class="Nav-account-dropdown__member">
        <ux-atoms-typo
          :text="$t('ux.molecules.navAccountDropdown.member')"
          as="p"
          class="Nav-account-dropdown__member-title"
          variant="text-heading-03"
        />
        <ux-atoms-typo :text="userName" as="p" class="Nav-account-dropdown__member-text" variant="text-heading-01" />
      </div>

      <div class="Nav-account-dropdown__reward">
        <ux-atoms-link :href="rewardPointsLink" class="Nav-account-dropdown__reward-link" target="_blank">
          <div class="Nav-account-dropdown__reward-container">
            <ux-atoms-typo
              :text="$t('ux.molecules.navAccountDropdown.pointsRewards')"
              as="span"
              class="Nav-account-dropdown__reward-title"
              color="white"
              variant="text-heading-03"
            />
            <ux-atoms-typo :text="$t('ux.molecules.navAccountDropdown.points', { n: userPoints })" as="span" color="white" variant="text-regular" />
          </div>
          <ux-atoms-icon class="Nav-account-dropdown__reward-icon" name="chevron-right" size="m" />
        </ux-atoms-link>
      </div>

      <div class="Nav-account-dropdown__disconnect">
        <ux-atoms-link :text="$t('ux.molecules.navAccountDropdown.logout')" :to="logoutLink" class="Nav-account-dropdown__link" rel="nofollow" />
      </div>
    </div>
    <ul class="Nav-account-dropdown__unlogged" v-else>
      <li class="Nav-account-dropdown__item">
        <ux-atoms-link
          :text="$t('ux.molecules.navAccountDropdown.login')"
          :to="loginLink"
          @click="gtmLoginEvent"
          class="Nav-account-dropdown__link"
          rel="nofollow"
        />
      </li>
      <li class="Nav-account-dropdown__item">
        <ux-atoms-link
          :text="$t('ux.molecules.navAccountDropdown.createAccount')"
          :to="createAccountLink"
          @click="gtmCreateAccountEvent"
          class="Nav-account-dropdown__link"
          rel="nofollow"
        />
      </li>
    </ul>
  </div>
</template>
<script lang="ts" setup>
import { getFocusableElements, getNextFocusableElement, getPreviousFocusableElement } from '~/helpers/accessibility';
import { gtmEvent, gtmPageName } from '~/helpers/gtm';

export interface NavAccountDropdown {
  dropdownIsOpen?: boolean;
}

withDefaults(defineProps<NavAccountDropdown>(), {
  dropdownIsOpen: false
});

const route = useRoute();
const navAccountDropdown = ref();
const focusableListElements = ref<HTMLElement[]>();

const userStore = useUserStore();
const { getAuthUrl } = useAuth();

const createAccountLink = computed(() => {
  return getAuthUrl('signup');
});

const loginLink = computed(() => {
  return getAuthUrl('login');
});

const logoutLink = computed(() => {
  return getAuthUrl('logout');
});

const rewardPointsLink = computed(() => {
  return useRewardPoints();
});

const userPoints = computed(() => {
  return userStore.loyaltyNbPoints || 0;
});

const userName = computed(() => {
  return `${userStore.firstName} ${userStore.lastName}`;
});

const gtmLoginEvent = () => {
  gtmEvent('cta_authentification', {
    pagename: gtmPageName(route.name as string)
  });
};

const gtmCreateAccountEvent = () => {
  gtmEvent('cta_enroll', {
    pagename: gtmPageName(route.name as string)
  });
};

const getListFocusableElements = () => {
  focusableListElements.value = getFocusableElements(navAccountDropdown.value) as HTMLElement[];
};

const focusPreviousListItem = (e: Event) => {
  e.preventDefault();
  if (e?.target) {
    const previousEl = getPreviousFocusableElement(e.target as HTMLElement, focusableListElements.value as HTMLElement[]);
    previousEl.focus();
  }
};

const focusNextListItem = (e: Event) => {
  e.preventDefault();
  if (e?.target) {
    const nextEl = getNextFocusableElement(e.target as HTMLElement, focusableListElements.value as HTMLElement[]);
    nextEl?.focus();
  }
};

onMounted(() => {
  getListFocusableElements();
});
</script>
<style lang="scss" scoped>
@use 'NavAccountDropdown.scss';
</style>
