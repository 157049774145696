<template>
  <ul
    :aria-hidden="!dropdownIsOpen"
    :id="uniqueControlId"
    @keydown.down="focusNextListItem($event)"
    @keydown.up="focusPreviousListItem($event)"
    class="Lang-dropdown"
    ref="langContainer"
  >
    <li
      :aria-selected="$i18n.locale === lang.code"
      :class="['Lang-dropdown__item', { 'Lang-dropdown__item--active': $i18n.locale === lang.code }]"
      :key="lang.code"
      role="option"
      v-for="lang in locales"
    >
      <ux-atoms-link
        :class="['Lang-dropdown__link', { 'Lang-dropdown__link--active': $i18n.locale === lang.code }]"
        :id="`Lang-dropdown__${lang.code}`"
        :lang="$i18n.locale === lang.code ? null : lang.code"
        :locale="lang.code"
        :tabindex="$i18n.locale === lang.code ? -1 : null"
        :target="'_parent'"
        :text="lang.code"
        @click="$emit('close-lang-dropdown')"
        @focusout="$emit('close-lang-dropdown')"
        component="switch-locale-path-link"
      />
    </li>
  </ul>
</template>
<script lang="ts" setup>
import { getFirstFocusableElement, getFocusableElements, getNextFocusableElement, getPreviousFocusableElement } from '~/helpers/accessibility';

const { locales } = useI18n();

export interface LangDropdown {
  dropdownIsOpen?: boolean;
  uniqueControlId?: string;
}

const props = withDefaults(defineProps<LangDropdown>(), {
  dropdownIsOpen: false,
  uniqueControlId: undefined
});

defineEmits(['close-lang-dropdown']);

const focusableListElements = ref<HTMLElement[]>();

const langContainer = ref<HTMLElement>();

const focusFirstFocusableElement = () => {
  const firstFocusableElement = langContainer.value ? getFirstFocusableElement(langContainer.value) : null;
  if (firstFocusableElement) {
    useFocus(firstFocusableElement as HTMLElement, { initialValue: true });
  }
};

const getListFocusableElements = () => {
  if (langContainer.value) {
    focusableListElements.value = getFocusableElements(langContainer.value) as HTMLElement[];
  }
};

const focusPreviousListItem = (e: Event) => {
  if (focusableListElements.value) {
    const previousEl = getPreviousFocusableElement(e.target as HTMLElement, focusableListElements.value);
    previousEl.focus();
  }
};

const focusNextListItem = (e: Event) => {
  if (focusableListElements.value) {
    const nextEl = getNextFocusableElement(e.target as HTMLElement, focusableListElements.value);
    nextEl.focus();
  }
};

watch(
  () => props.dropdownIsOpen,
  (value) => {
    if (value) {
      nextTick(() => {
        focusFirstFocusableElement();
      });
    }
  }
);

onMounted(() => {
  getListFocusableElements();
});
</script>
<style lang="scss">
@use 'LangDropdown.scss';
</style>
