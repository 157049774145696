<template>
  <div class="Header-dropdown" data-behavior="dropdown">
    <div class="Header-dropdown__button-container" ref="dropdownButton">
      <ux-atoms-button
        :aria-controls="uniqueDropdownId"
        :aria-expanded="dropdownIsOpen"
        @click="toggleDropdown"
        aria-haspopup="listbox"
        class="Header-dropdown__button"
        inherit-color
        no-border
        size="s"
        variant="action"
      >
        <div class="Header-dropdown__button-content">
          <slot name="button" />
        </div>
      </ux-atoms-button>
    </div>
    <transition name="fade">
      <div
        :id="uniqueDropdownId"
        @keydown.esc="closeDropdown(true)"
        class="Header-dropdown__dropdown"
        ref="dropdown"
        role="listbox"
        v-show="dropdownIsOpen"
      >
        <slot name="content" />
      </div>
    </transition>
  </div>
</template>
<script lang="ts" setup>
import { getFirstFocusableElement } from '~/helpers/accessibility';

const dropdownIsOpen = ref(false);
const dropdownButton = ref<HTMLElement>();
const dropdown = ref<HTMLElement>();
const uniqueDropdownId = useId();

const toggleDropdown = () => {
  dropdownIsOpen.value = !dropdownIsOpen.value;
};

const closeDropdown = (focusBtn?: boolean) => {
  dropdownIsOpen.value = false;
  if (focusBtn) {
    (dropdownButton.value?.childNodes[0] as HTMLElement).focus();
  }
};

const focusFirstFocusableElement = () => {
  if (!dropdown.value) {
    return;
  }
  const firstFocusableElement = getFirstFocusableElement(dropdown.value);
  if (firstFocusableElement) {
    useFocus(firstFocusableElement as HTMLElement, { initialValue: true });
  }
};

const activeElement = useActiveElement();

onClickOutside(dropdownButton, (e) => {
  if (e.target !== dropdownButton?.value && !dropdownButton?.value?.contains(e.target as HTMLElement)) {
    closeDropdown();
  }
});

watch(activeElement, (el) => {
  if (!dropdown.value?.contains(el as HTMLElement) && el !== dropdownButton?.value && !dropdownButton?.value?.contains(el as HTMLElement)) {
    closeDropdown();
  }
});

watch(
  () => dropdownIsOpen.value,
  (value) => {
    if (value) {
      nextTick(() => {
        focusFirstFocusableElement();
      });
    }
  }
);
</script>
<style lang="scss" scoped>
@use 'HeaderDropdown.scss';
</style>
