<template>
  <div class="Lang-switch">
    <div class="Lang-switch__button-container" ref="langSwitchButton">
      <span :id="uniqueLabelId" class="accessibility-sr-only">{{ $t('ux.molecules.langSwitch.title') }}</span>
      <ux-atoms-button
        :aria-activedescendant="activeDescendant"
        :aria-controls="uniqueControlId"
        :aria-expanded="dropdownIsOpen"
        :aria-labelledby="uniqueLabelId"
        @click="toggleDropdown"
        aria-haspopup="listbox"
        class="Lang-switch__button"
        inherit-color
        no-border
        role="combobox"
        size="s"
        variant="action"
      >
        <div class="Lang-switch__button-content">
          <ux-atoms-typo :text="$i18n.locale" as="span" class="Lang-switch__text" color="dark" variant="text-xsmall" />
          <ux-atoms-icon
            :class="['Lang-switch__button-icon', { 'Lang-switch__button-icon--reversed': dropdownIsOpen }]"
            name="chevron-down"
            size="s"
          />
        </div>
      </ux-atoms-button>
    </div>
    <transition name="fade">
      <ux-molecules-lang-dropdown
        :dropdown-is-open="dropdownIsOpen"
        :unique-control-id="uniqueControlId"
        @close-lang-dropdown="closeDropdown"
        @keydown.esc="closeDropdown(true)"
        ref="dropdown"
        v-show="dropdownIsOpen"
      />
    </transition>
  </div>
</template>
<script lang="ts" setup>
const dropdownIsOpen = ref(false);
const langSwitchButton = ref<HTMLElement>();
const uniqueControlId = useId();
const uniqueLabelId = useId();

const { locale } = useI18n();

const activeDescendant = computed(() => `Lang-dropdown__${locale.value}`);

const toggleDropdown = () => {
  dropdownIsOpen.value = !dropdownIsOpen.value;
};

const closeDropdown = (focusBtn: boolean) => {
  dropdownIsOpen.value = false;
  if (focusBtn) {
    (langSwitchButton.value?.childNodes[0] as HTMLElement)?.focus();
  }
};

onClickOutside(langSwitchButton, (e) => {
  if (e.target !== langSwitchButton?.value && !langSwitchButton?.value?.contains(e.target as HTMLElement) && dropdownIsOpen.value) {
    dropdownIsOpen.value = false;
  }
});
</script>
<style lang="scss" scoped>
@use 'LangSwitch.scss';
</style>
