<template>
  <nav :aria-label="$t('ux.molecules.mainNav.aria')" :class="['Main-nav', { 'Main-nav--homepage': isHomepage }]" role="navigation">
    <div class="Grid-layout Main-nav__grid">
      <div class="Main-nav__container">
        <ux-atoms-button @click="toggleMenu" class="Main-nav__button Main-nav__burger" no-border variant="action">
          <div class="Main-nav__burger-icons">
            <ux-atoms-icon :class="['Main-nav__burger-icon', { 'Main-nav__burger-icon--hidden': dropdownIsOpen }]" name="burger" size="l" />
            <ux-atoms-icon :class="['Main-nav__burger-icon', { 'Main-nav__burger-icon--hidden': !dropdownIsOpen }]" name="close-menu" size="l" />
          </div>
        </ux-atoms-button>
        <ux-atoms-logo class="Main-nav__logo" />
        <ux-molecules-main-nav-menu :dropdown-is-open="dropdownIsOpen" @dropdown::close="toggleMenu" />

        <ux-molecules-header-dropdown>
          <template #button>
            <div :class="['Main-nav__account-button', { 'Main-nav__account-button--logged': userStore.hasLoadedUser }]">
              <span class="accessibility-sr-only">{{ $t('ux.molecules.mainNav.accountButton') }}</span>
              <ux-atoms-icon name="account" size="l" />
            </div>
          </template>
          <template #content>
            <ux-molecules-nav-account-dropdown />
          </template>
        </ux-molecules-header-dropdown>
      </div>
    </div>
  </nav>
</template>
<script setup lang="ts">
import { getPageName } from '~/helpers/getPageName';

const userStore = useUserStore();
const route = useRoute();
const dropdownIsOpen = ref<boolean>(false);

const toggleMenu = () => {
  dropdownIsOpen.value = !dropdownIsOpen.value;
};

const isHomepage = computed(() => {
  return getPageName(route.name)?.startsWith('country___');
});
</script>
<style lang="scss" scoped>
@use 'MainNav.scss';
</style>
